import { useContext, useEffect, useState, useRef } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ApplicationContext } from "./contexts/ApplicationContext.js";
import { AuthContext } from "./contexts/AuthContext.js";
import { ModalContext } from "./contexts/ModalContext.js";
import { MessageBannerContext } from "./contexts/MessageBannerContext.js";

import styles from "../styles/Modal.module.scss";

import spinner from "../img/spinner.svg";
import DocumentWithPenIcon from "../img/document-with-pen.svg";
import DocumentWithLockIcon from "../img/document-with-lock.svg";

// Constants for modal names to prevent typos
const MODAL_NAMES = {
  INITIAL_POLICY: "InitialPolicy",
  TEMPORARY_ACCESS_POLICY: "TemporaryAccessPolicy",
  HISTORY_CONSENT: "HistoryConsent",
  DEACTIVATE_HISTORY: "DeactivateConversationHistory",
};

const ACTION_BUTTONS = [
  "AcceptInitialPolicy",
  "AcceptTemporaryAccessPolicy",
  "AcceptHistoryConsent",
  "DeactivateConversationHistory",
];

const MODAL_CONFIGS = {
  [MODAL_NAMES.INITIAL_POLICY]: {
    icon: {
      src: DocumentWithLockIcon,
      alt: "Policyikon",
      width: 50,
      height: 50,
    },
    className: styles.initialPolicy,
    buttonContainer: {
      buttons: [
        {
          name: "AcceptInitialPolicy",
          text: "Jag förstår!",
        },
      ],
    },
  },
  [MODAL_NAMES.TEMPORARY_ACCESS_POLICY]: {
    icon: {
      src: DocumentWithLockIcon,
      alt: "Policyikon",
      width: 50,
      height: 50,
    },
    className: styles.initialPolicy,
    buttonContainer: {
      buttons: [
        {
          name: "AcceptTemporaryAccessPolicy",
          text: "Jag förstår!",
        },
      ],
    },
  },
  [MODAL_NAMES.HISTORY_CONSENT]: {
    icon: {
      src: DocumentWithPenIcon,
      alt: "Policyikon",
      width: 50,
      height: 50,
    },
    title: "Aktivera historik",
    paragraphs: [
      "När du aktiverar historikfunktionen börjar systemet att lagra dina konversationer i tjänsten, så att du kan återuppta tidigare konversationer eller hämta tidigare skapat material.",
      "Vi krypterar självklart all lagrad konversationshistorik, så att endast du har tillgång till den.",
      "En konversation sparas i 90 dagar efter att du senast skrev i den, och du kan radera en specifik konversation när du själv önskar.",
      "Om du bestämmer dig för att du inte längre vill att dina konversationer ska sparas, kan du när som helst inaktivera historikfunktionen, och då rensas hela din konversationshistorik.",
    ],
    className: styles.historyConsent,
    buttonContainer: {
      buttons: [
        {
          name: "ExitModal",
          text: "Nej tack!",
        },
        {
          name: "AcceptHistoryConsent",
          text: "Aktivera nu.",
        },
      ],
    },
  },
  [MODAL_NAMES.DEACTIVATE_HISTORY]: {
    icon: {
      src: DocumentWithPenIcon,
      alt: "Policyikon",
      width: 50,
      height: 50,
    },
    title: "Inaktivera historik",
    paragraphs: [
      "Genom att inaktivera historiken slutar systemet att lagra dina konversationer i tjänsten och hela din konversationshistorik kommer omgående att raderas.",
      "Du kan givetvis aktivera historikfunktionen igen om du ångrar dig i framtiden.",
      "Är du säker på att du vill inaktivera historiken och radera all din konversationshistorik?",
    ],
    className: styles.deactivateConversationHistory,
    buttonContainer: {
      buttons: [
        {
          name: "ExitModal",
          text: "Nej!",
        },
        {
          name: "DeactivateConversationHistory",
          text: "Inaktivera nu.",
        },
      ],
    },
  },
};

export default function Modal() {
  const { setUser } = useContext(AuthContext);
  const { displayModal, setDisplayModal } = useContext(ModalContext);
  const { addMessageBanner } = useContext(MessageBannerContext);
  const { applicationConfig } = useContext(ApplicationContext);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [checkboxWarning, setCheckboxWarning] = useState("");
  const [markdownContent, setMarkdownContent] = useState("");
  const [loading, setLoading] = useState(false);
  const dialogRef = useRef(null);

  const modalConfig = applicationConfig.modals.find(
    (m) => m.name === displayModal
  );

  // Combine static and dynamic config
  const currentModalConfig = {
    ...MODAL_CONFIGS[displayModal],
    ...modalConfig,
  };

  const handleApiRequest = async (endpoint, method = "PUT", onSuccess) => {
    setLoading(true);
    try {
      const response = await fetch(endpoint, {
        method,
        credentials: "include",
      });

      const contentType = response.headers.get("Content-Type");
      const isJson = contentType?.includes("application/json");

      // Read the response body once and store it
      let responseData = null;
      if (isJson) {
        try {
          responseData = await response.json();
        } catch (e) {
          console.error("Failed to parse JSON response:", e);
        }
      }

      if (!response.ok) {
        throw new Error(responseData?.message || "Request failed");
      }

      onSuccess();

      if (responseData?.message) {
        addMessageBanner({
          position: "topMiddle",
          type: "success",
          text: responseData.message,
        });
      }
    } catch (error) {
      console.error("API request failed:", error);
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: "Ett oväntat fel uppstod. Vänligen försök igen senare.",
      });
    } finally {
      setLoading(false);
      setDisplayModal("");
    }
  };

  const handleAction = async (actionName) => {
    const actions = {
      AcceptInitialPolicy: () => {
        if (currentModalConfig.useCheckbox && !checkboxChecked) {
          setCheckboxWarning(currentModalConfig.checkboxWarning);
          return;
        }

        handleApiRequest("/api/auth/accept-initial-policy", "PUT", () =>
          setUser((prev) => ({ ...prev, initialPolicy: 1 }))
        );
      },

      AcceptTemporaryAccessPolicy: () => {
        if (currentModalConfig.useCheckbox && !checkboxChecked) {
          setCheckboxWarning(currentModalConfig.checkboxWarning);
          return;
        }
        setDisplayModal("");
      },

      AcceptHistoryConsent: () => {
        handleApiRequest("/api/conversations/activate-history", "PUT", () =>
          setUser((prev) => ({ ...prev, conversations: 1 }))
        );
      },

      DeactivateConversationHistory: () => {
        handleApiRequest("/api/conversations/deactivate-history", "PUT", () =>
          setUser((prev) => ({ ...prev, conversations: 0 }))
        );
      },

      ExitModal: () => setDisplayModal(""),
    };

    if (!Object.keys(actions).includes(actionName)) {
      console.warn(`Unknown action: ${actionName}`);
      return;
    }

    await actions[actionName]?.();
  };

  useEffect(() => {
    const currentDialog = dialogRef.current;
    if (currentDialog) {
      currentDialog.showModal();
      return () => currentDialog.close();
    }
  }, [displayModal]);

  useEffect(() => {
    if (currentModalConfig?.loadMarkdownFromFile) {
      fetch(`/${displayModal}.md`)
        .then((response) => {
          if (!response.ok) throw new Error("Failed to fetch markdown");
          return response.text();
        })
        .then(setMarkdownContent)
        .catch((error) => {
          console.error("Error fetching markdown:", error);
          setMarkdownContent("");
        });
    }
  }, [displayModal, currentModalConfig?.loadMarkdownFromFile]);

  if (!displayModal || !currentModalConfig) return null;

  return (
    <dialog
      ref={dialogRef}
      className={`${styles.dialog} ${currentModalConfig.className}`}
      onClose={() => setDisplayModal("")}
    >
      <img
        src={
          currentModalConfig.useLogo ? "/logo.png" : currentModalConfig.icon.src
        }
        alt={currentModalConfig.useLogo ? "Logo" : currentModalConfig.icon.alt}
        width={
          !currentModalConfig.useLogo
            ? currentModalConfig.icon.width
            : undefined
        }
        height={
          !currentModalConfig.useLogo
            ? currentModalConfig.icon.height
            : undefined
        }
        className={currentModalConfig.useLogo ? styles.logo : ""}
      />

      <h1>{currentModalConfig.title}</h1>

      {currentModalConfig.loadMarkdownFromFile ? (
        <Markdown remarkPlugins={[remarkGfm]}>{markdownContent}</Markdown>
      ) : (
        currentModalConfig.paragraphs?.map((paragraph, index) => (
          <p key={index} style={{ textAlign: "center" }}>
            {paragraph}
          </p>
        ))
      )}

      {currentModalConfig.useCheckbox && (
        <div className={styles.checkboxContainer}>
          <label>
            <input
              type="checkbox"
              checked={checkboxChecked}
              onChange={(e) => setCheckboxChecked(e.target.checked)}
            />
            {currentModalConfig.checkboxLabel}
          </label>
          {checkboxWarning && (
            <p className={styles.warning}>{checkboxWarning}</p>
          )}
        </div>
      )}

      <div className={styles.buttonContainer}>
        {currentModalConfig.buttonContainer.buttons.map((button, index) => {
          const isActionButton = ACTION_BUTTONS.includes(button.name);
          return (
            <button
              key={index}
              onClick={() => handleAction(button.name)}
              disabled={loading && isActionButton}
            >
              {loading && isActionButton ? (
                <img src={spinner} width={18} alt="Laddar.." />
              ) : (
                button.text
              )}
            </button>
          );
        })}
      </div>
    </dialog>
  );
}
